import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";

//s-alert
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";

//context
import AuthState from "./context/auth/AuthState";
import setAuthToken from "./utils/setAuthToken";
import SiteContext from "./context/site/SiteState";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Account/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
const Welcome = React.lazy(() => import("./views/Pages/Welcome"));

const App = () => {
  return (
    <SiteContext>
      <AuthState>
        <BrowserRouter>
          <Alert stack={{ limit: 10 }} />
          <React.Suspense fallback={loading()}>
            <Switch>
            <Route
                exact
                path="/welcome"
                name="Welcome Page"
                render={props => <Welcome {...props} />}
              />
              <Route
                exact
                path="/login"
                name="Login Page"
                render={props => <Login {...props} />}
              />            
              <Route
                exact
                path="/register"
                name="Register Page"
                render={props => <Register {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={props => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={props => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={props => <DefaultLayout {...props} />}
              />
              <Route component={Page404} />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </AuthState>
    </SiteContext>
  );
};

export default App;
